import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';

const duration = 0.1;

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: {duration: duration},
  },
};

export default function Layout(props: any) {
  return (
    <div>
      <AnimatePresence>
        <motion.main
          key={typeof window !== 'undefined' && typeof window.location !== 'undefined' ? window.location.pathname : props.path}
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          {props.children}
        </motion.main>
      </AnimatePresence>

    </div>
  );
}
