import './src/styles.css';


import React from "react";
import Layout from "./src/pages/layout/Layout";

const transitionDelay = 200;

export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>;
};

